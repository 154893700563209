import React, { useState } from 'react';
import ModalProducto from './ModalProducto';

import gondola from '../imagenes/articulos/gondola.jpg'
import supergondola from '../imagenes/articulos/super.jpg'
import plano from '../imagenes/articulos/plano.jpg'
import mastroplus from '../imagenes/articulos/mastroplus.jpg'
import planito from '../imagenes/articulos/planito.jpg'
import planchita from '../imagenes/articulos/planchita.jpg'
import marfil from '../imagenes/articulos/marfil.jpg'
import laqueado from '../imagenes/articulos/laqueado.jpg'
import gondolita from '../imagenes/articulos/gondolita.jpg'
import europa from '../imagenes/articulos/europa.jpg'
import blanconegro from '../imagenes/articulos/blancoynegro.jpg'
import bahiana from '../imagenes/articulos/bahiana.jpg'
import robusta from '../imagenes/articulos/robusta.jpg'
import lavacoche from '../imagenes/articulos/lavacoche.jpg'

import '../estilos/style-productos.css'

function Productos() {
    return (
      <div className="contenedor--productos">

        <h1 className="titulo--productos--principal">Estos son nuestros productos</h1>

        {/* Componentes para cada categoría de productos */}
        <CategoriaProductos titulo="Escobillones" productos={escobillonesData} />  

        <CategoriaProductos titulo="Escobas" productos={escobasData} />

        <CategoriaProductos titulo="Cepillos" productos={cepillosData} />

      </div>
    );
  }
  
  // Componente reutilizable para una categoría de productos
  function CategoriaProductos({ titulo, productos }) {

    const [mostrarModal, setMostrarModal] = useState(false);
    const [productoSeleccionado, setProductoSeleccionado] = useState(null);

    const abrirModal = (producto) => {
        setProductoSeleccionado(producto);
        setMostrarModal(true);
      };
    
      const cerrarModal = () => {
        setMostrarModal(false);
        setProductoSeleccionado(null);
      };
  
    return (
      <div className={titulo.toLowerCase()}>
        <h2 className="titulos__categorias__productos">{titulo}</h2>
        <ul className="lista__productos">
          {productos.map((producto, index) => (

            <li
                key={index} 
                className="lista__elemento__producto"
                onClick={() => abrirModal(producto)}
            >
            
                <p className="nombre__articulo">{producto.nombre}</p>
                <img src={producto.imagen} alt={producto.nombre} />
        
            </li>
          ))}
        </ul>

        {mostrarModal && productoSeleccionado && (
        <ModalProducto
          producto={productoSeleccionado}
          cerrarModal={cerrarModal}
        />
      )}

      </div>
    );
  }
  
  // Datos de productos para cada categoría
  //ESCOBILLONES
  const escobillonesData = [
    { nombre: "Escobillón góndola", imagen: gondola, descripcion: "Escobillón plástico curvo con rosca", filas: "6 hileras", fibra: "Fibra plumada de 8.5cm, colores variados", embalaje: "Embalaje: Caja por 12 u. ● 3.5 Kg aprox ● 41*34*24 ● Vol 0.033456" , ean:"EAN: 7798127530069"},
    { nombre: "Escobillón super", imagen: supergondola, descripcion: "Escobillón curvo plástico con rosca y fibras extra largas", filas: "6 hileras" , fibra: "Fibra plumada de 11cm, colores variados", embalaje: "Embalaje: Caja por 12 u. ● 4 Kg aprox ● 43*35*29 ● Vol 0.043645" , ean: "EAN: 7798127530090"},
    { nombre: "Escobillón laqueado", imagen: laqueado, descripcion: "Escobillón curvo plástico con rosca y cuerpo símil madera", filas: "6 hileras", fibra: "Fibra plumada de 8.5cm, colores variados", embalaje: "Embalaje: Caja por 12 u. ● 3.5 Kg aprox ● 41*34*24 ● Vol 0.033456" , ean: "EAN: 7798127530052"},
    { nombre: "Escobillón marfil", imagen: marfil, descripcion: "Escobillón curvo plástico con rosca y cuerpo símil madera", filas: "6 hileras", fibra: "Fibra plumada de 8.5cm de color champagne", embalaje: "Embalaje: Caja por 12 u. ● 3.5 Kg aprox ● 41*34*24 ● Vol 0.033456" , ean: "EAN: 7798127530369"},
    { nombre: "Escobillón blanco y negro", imagen: blanconegro, descripcion: "Escobillón curvo plástico con rosca y cuerpo símil madera", filas: "6 hileras", fibra: "Fibra plumada de 8.5cm en color blanco y negro", embalaje: "Embalaje: Caja por 12 u. ● 3.5 Kg aprox ● 41*34*24 ● Vol 0.033456" , ean: "EAN: 7798127530083"},
    { nombre: "Escobillón gondolita", imagen: gondolita, descripcion: "Escobillón curvo liviano con rosca", filas: "5 hileras", fibra: "Fibra plumada de 8.5cm, colores variados", embalaje: "Embalaje: Caja por 12 u. ● 3 Kg aprox ● 39*32*23 ● Vol 0.028704" , ean: "EAN: 7798127530106"}

  ];
  //ESCOBAS
  const escobasData = [
    { nombre: "Escoba bahiana", imagen: bahiana, descripcion: "Escobita liviana plástica con rosca", filas: "4 hileras", fibra: "Fibra plumada de 11cm, colores variados", embalaje: "Embalaje: Caja por 12 u. ● 2 Kg aprox ● 45*38*15 ● Vol 0.02565" , ean:"EAN: 7981127530113"},
    { nombre: "Escoba europa", imagen: europa, descripcion: "Escoba plástica reforzada con rosca", filas: "4 hileras", fibra: "Fibra plumada de 11cm, colores variados", embalaje: "Embalaje: Caja por 12 u. ● 2.5 Kg aprox ● 45*31*20 ● Vol 0.0279" , ean:"EAN: 7798127530137"},
    { nombre: "Escoba robusta", imagen: robusta, descripcion: "Escoba pesada de fibra larga y con rosca", filas: "5 hileras", fibra: "Fibra plumada de 11cm, colores variados", embalaje: "Caja por 12 u. ● 3 Kg aprox ● 58*31*15 ● Vol 0.02697" , ean:"EAN: 7798127530106"}
    
  ];
  //CEPILLOS
  const cepillosData = [
    { nombre: "Cepillo plano Mastro plus", imagen: mastroplus, descripcion: "Cepillo plano grande de plástico con rosca", filas: "6 hileras", fibra: "Fibra plumada de 8.5cm, colores variados", embalaje: "Embalaje: Caja por 12 u. ● 3.5 Kg aprox ● 41*34*24 ● Vol 0.033456" , ean:"EAN: 7792387001321"},
    { nombre: "Cepillo plano Mastro", imagen: plano, descripcion: "Cepillo plano plástico con rosca", filas: "5 hileras", fibra: "Fibra plumada de 8.5cm, colores variados", embalaje: "Embalaje: Caja por 12 u. ● 3 Kg aprox ● 41*34*24 ● Vol 0.033456" , ean:"EAN: 7792387001321"},
    { nombre: "Cepillo plano", imagen: planito, descripcion: "Cepillo liviano plástico con rosca", filas: "5 hileras", fibra: "Fibra plumada de 8.5cm, colores variados", embalaje: "Embalaje: Caja por 24 u. ● 5 Kg aprox ● 59*30*24 ● Vol 0.04248" , ean:"EAN: 7792387001321"},
    { nombre: "Cepillo lavacoche", imagen: lavacoche, descripcion: "Cepillo de mano para automóvil", filas: "7 hileras", fibra: "Fibra plumada de 7cm, monocolor", embalaje: "Embalaje: Caja por 12 u. ● 2 Kg aprox ● 44*23*16 ● Vol 0.016192" , ean:"EAN: 7798127530120"},
    { nombre: "Cepillo planchita", imagen: planchita, descripcion: "Cepillo de mano con mango ergonómico", filas: "6 hileras", fibra: "Fibra sin plumado de 4cm, monocolor", embalaje: "Embalaje: Caja por 24 u. ● 3 Kg aprox ● 38*32*15 ● Vol 0.01824" , ean:"EAN: 7798127530151"}
  ];
  
  export default Productos;
  