//routes se usa para poder navegar entre liks de menu entre paginas
import { Routes, Route } from "react-router-dom"
import React, { useState, useEffect } from 'react';
import Inicio from "./components/Inicio"
import Empresa from "./components/Empresa"
import Contacto from "./components/Contacto"
import Productos from "./components/Productos"
import './estilos/style.css'
import './estilos/reset.css'
import { Link } from "react-router-dom";

function App()
{
    const [precargaBanner, setImagenInicial] = useState(null);

  useEffect(() => {
    const precargarImagen = async () => {
      try {
        const imagen = await import('./imagenes/general/bannercolor.webp');
        setImagenInicial(imagen.default);
      } catch (error) {
        console.error('Error al precargar la imagen:', error);
      }
    };
        precargarImagen();
  }, []);

    return( 

    <div className = "app">
    
        <header>   
         <div className="contenedor__navegador">

         {precargaBanner && (<img className="banner" src={precargaBanner} alt="Imagen de encabezado con la marca"/>)}

            <nav  className="menu__nav">

                <Link className="enlace__navegacion" to="/">Home</Link>
                <Link className="enlace__navegacion" to="/Productos">Productos</Link>
                <Link className="enlace__navegacion" to="/Empresa">Empresa</Link>
                <Link className="enlace__navegacion" to="/Contacto">Contacto</Link>
                
            </nav>
         </div>
    </header>

    <Routes>
        <Route path="/" element={<Inicio />} />
        <Route path="/Productos" element={<Productos />} />
        <Route path="/Empresa" element={<Empresa />} />
        <Route path="/contacto" element={<Contacto />} />
        </Routes>

    <footer className="pie__general">

<div className="pie__contenedor">

    <div className="pie__elemento">

        <section className="pie__navegador">
            <h2 className="pie__titulos">menu</h2>
            <ul className="pie__navegador__lista">

                <li className="pie__nav__enlace"><Link className="pie__enlace__navegacion" to="/">Home</Link></li>
                <li className="pie__nav__enlace"><Link className="pie__enlace__navegacion" to="/Productos">Productos</Link></li>
                <li className="pie__nav__enlace"><Link className="pie__enlace__navegacion" to="/Empresa">Empresa</Link></li>
                <li className="pie__nav__enlace"><Link className="pie__enlace__navegacion" to="/Contacto">Contacto</Link></li>

            </ul>

        </section>
    </div>

    <div className="pie__elemento">

        <section className="pie__general__direcciones">

            <h3 className="pie__titulos">Contactanos</h3>

            <ul className="pie__direcciones__lista">
                <li className="pie__direcciones__linea">
                Muñoz 752, Muñiz, Buenos Aires, Argentina
                </li>
                <li className="pie__direcciones__linea">
                ventas@mastro.com.ar
                </li>
                <li className="pie__direcciones__linea">
                11 5182-7707
                </li>
                <li className="pie__direcciones__linea">
                Lunes a viernes de 9:00 a 18:00hs
                </li>
            </ul>

        </section>

    </div>

    <div className="pie__elemento">

        <section className="pie__general__redes">
            <h4 className="pie__titulos">¡seguinos en las redes!</h4>
            <nav className="pie__redes__lista">
                <a className="pie__redes__enlace" href="https://www.facebook.com/mastroescobillones/"> <img className="pie__redes__logo" src={require(`./imagenes/iconos/facebook.png`)} alt="Facebook Logo"/> </a>
                <a className="pie__redes__enlace" href="https://www.instagram.com/mastroescobillones/"> <img className= "pie__redes__logo" src = {require(`./imagenes/iconos/instagram.png`)} alt="Instagram Logo"/> </a>
                <a className="pie__redes__enlace" href="https://www.youtube.com/channel/UCuAwqqMRptg-l4ivFDLXQOg"> <img className="pie__redes__logo" src={require(`./imagenes/iconos/youtube.png`)} alt="YouTube Logo"/></a>
                <a className="pie__redes__enlace" href="https://twitter.com/Fabrica_Mastro"> <img className="pie__redes__logo" src={require(`./imagenes/iconos/twitter.png`)} alt="Twitter Logo"/></a>
            </nav>
        </section>

    </div>

</div>
</footer>

    </div>
);
       
}

export default App