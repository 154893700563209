import React from 'react';

const MapGoogle = () => {
  return (
    <div>
      <iframe
        title="Google Maps"
        width="600"
        height="450"
        frameBorder="0"
        style={{ border: 0 }}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3286.288947586232!2d-58.702875984238176!3d-34.54623876196715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcbd0653ca0147%3A0x3c775bfa6cde372e!2sMu%C3%B1oz%20752%2C%20B1662AOP%20Mu%C3%B1iz%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1646261628772!5m2!1ses-419!2sar"
        allowFullScreen=""
        loading='lazy'
      ></iframe>
    </div>
  );
};

export default MapGoogle;
