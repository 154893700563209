import React, { useState, useEffect } from 'react';
import '../estilos/style-home.css'
import '../estilos/reset.css'


function Inicio() {

  const [precargaImagenInicial, setImagenInicial] = useState(null);

  useEffect(() => {
    const precargarImagen = async () => {
      try {
        const imagen = await import('../imagenes/show/logistica 2_low.jpg');
        setImagenInicial(imagen.default);
      } catch (error) {
        console.error('Error al precargar la imagen:', error);
      }
    };

    precargarImagen();

  }, []);

  return (

<div className= "app-container">
    <div className='contenedor--principal--inicio'>

                <h1 className="titulo--inicio--principal">Bienvenido a nuestro sitio web!</h1>

                <p className='texto--secundario'>Somos fabricantes de escobillones, escobas y cepillos de la más alta calidad, con más de 40 años en el mercado.</p>

        <div className="contenedor__promocion">

          {precargaImagenInicial && (<img className="imagen-bienvenida" src={precargaImagenInicial} alt="imagen de destacados"/>)}

        </div>

              <div className='imagen-texto'>
                <p className="sobretexto__intro">●Soluciones a medida para tu empresa</p>
                <p className="sobretexto__intro">●logística a todo el país</p>
                <p className="sobretexto__promocion">●Experiencia y simplicidad</p>
                <p className="sobretexto__promocion">●calidad y precio</p>
              </div>

    </div>

</div>

    
  );
}

export default Inicio;