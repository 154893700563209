import React from 'react';
import MapGoogle from './mapaGoogle';
import  '../estilos/style-contacto.css';

const emailAddress = "info@mastro.com.ar"
const emailAddress2 = "ventas@mastro.com.ar"
const direccionPrincipal =  "Muñoz 752, Muñiz, Buenos Aires, Argentina."
const telefono = "(+54) 11 5182-7707"

function Contacto() {
  return (
    <div className="contenedor--general--contacto">
        <h1 className="titulo--contacto--principal">Contáctate con nosotros</h1>

        <div className="contenedor--datos--contacto" >

         <div className='contendor--datos--textos'>

         <p className='contacto--titulos'> Vías de contacto:</p>
          <p>Podés llamarnos a nuestro numero de atanción al cliente</p> 
          <p className='contacto--info--importante'> tel/whatsapp: {telefono}</p>
          <p>Podés enviarnos un correo electrónico</p>
          <p className='contacto--info--importante' >Para información: {emailAddress} o <a className="contacto--mail--link" href={`mailto:${emailAddress}`}>clic acá</a> </p>
          
          <p className='contacto--info--importante'>Para comercio: {emailAddress2} o  <a className="contacto--mail--link" href={`mailto:${emailAddress2}`}>clic acá</a> </p>
          
          <p>También podés encontrarnos en nuestra oficina de atención al cliente: </p>
          <p className='contacto--info--importante'>{direccionPrincipal}</p>

         </div>
        
          

          <div className='contacto--contenedor--googlemap'>
                <MapGoogle></MapGoogle> 
                <p></p>
          </div>
          
        </div>
        
        <div className="contenedor--contacto--redes">
          <p className='contacto--titulos'>Estamos también en redes </p>
          <p className='contacto--texto--redes'>Podés comunicarte atreves de nuestras redes sociales y enterarte de promociones y novedades</p>
                <a className="contacto--redes--link" href="https://www.facebook.com/mastroescobillones/"> <img className="redes--logo" src={require(`../imagenes/iconos/facebook.png`)} alt="Facebook Logo"/> </a>
                <a className="contacto--redes--link" href="https://www.instagram.com/mastroescobillones/"> <img className= "redes--logo" src = {require(`../imagenes/iconos/instagram.png`)} alt="Instagram Logo"/> </a>
                <a className="contacto--redes--link" href="https://www.youtube.com/channel/UCuAwqqMRptg-l4ivFDLXQOg"> <img className="redes--logo" src={require(`../imagenes/iconos/youtube.png`)} alt="YouTube Logo"/></a>
                <a className="contacto--redes--link" href="https://twitter.com/Fabrica_Mastro"> <img className="redes--logo" src={require(`../imagenes/iconos/twitter.png`)} alt="Twitter Logo"/></a>
        </div>

              

    </div>
  );
}

export default Contacto