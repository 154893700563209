import React, { useEffect, useRef } from 'react';
import '../estilos/style-modal.css'

function ModalProducto({ producto, cerrarModal }) {

  const modalRef = useRef(null);

  useEffect(() => {
    // hace foco el contenedor del modal cuando se crea el componente.
    if (modalRef.current) {
      modalRef.current.focus();
    }
  }, []);

  const manejoPulsadoEsc = (event) => {
    if (event.keyCode === 27) {
      cerrarModal();
    }
  };

  return (
    
    <div className="modal">
      <div onKeyDown={manejoPulsadoEsc} tabIndex={0} ref={modalRef} className="modal-contenido">
        <button className="cerrar-modal" onClick={cerrarModal}>
          X
        </button>
        <h3 className='nombre__articulo__detalle'>{producto.nombre}</h3>
        <img className='articulo__detalle__foto' src={producto.imagen} alt={producto.nombre} />
        <p className='articulo__detalle__basicos'>{producto.descripcion}</p>
        <p className='articulo__detalle__basicos'>{producto.filas}</p>
        <p className='articulo__detalle__basicos'>{producto.fibra}</p>
        <p className='articulo__detalle'>{producto.embalaje}</p>
        <p className='articulo__detalle'>{producto.ean}</p>
      </div>
    </div>
  );
}

export default ModalProducto;
