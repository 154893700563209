import React, { useState, useEffect, useRef } from 'react';
import modalImg1 from '../imagenes/show/injectora_low.jpg'
import modalImg2 from '../imagenes/show/matriz_low.jpg'
import modalImg3 from '../imagenes/show/manufactura_low.jpg'
import modalImg4 from '../imagenes/show/manufactura2_low.jpg'
import modalImg5 from '../imagenes/show/instalaciones_low.jpg'
import modalImg6 from '../imagenes/show/logistica_low.jpg'
import modalImg7 from '../imagenes/show/materiales.jpg'
import modalImgLarge1 from '../imagenes/show/injectora.jpg'
import modalImgLarge2 from '../imagenes/show/matriz.jpg'
import modalImgLarge3 from '../imagenes/show/manufactura.jpg'
import modalImgLarge4 from '../imagenes/show/manufactura2.jpg'
import modalImgLarge5 from '../imagenes/show/instalaciones.jpg'
import modalImgLarge6 from '../imagenes/show/logistica.jpg'
import modalImgLarge7 from '../imagenes/show/materiales.jpg'
import '../estilos/style-empresa.css'


function Empresa(){

  const modalRef = useRef(null);

  useEffect(() => {
    // hace foco el contenedor del modal cuando se crea el componente.
    if (modalRef.current) {
      modalRef.current.focus();
    }
  }, []);

  const manejoPulsadoEsc = (event) => {
    if (event.keyCode === 27) {
      cerrarModal();
    }
  };


    const [slideIndex, setSlideIndex] = useState(null);

    const [imagenes] = useState([
       { src: modalImgLarge1, caption: 'Contamos con maquinaria para para toda la producción de nuestros artículos'},
       { src: modalImgLarge2, caption: 'Con matrices para injección plástica, ideamos y creamos bases propias y únicas para nuestros modelos'},
       { src: modalImgLarge3, caption: 'Tecnología de última generación para una eficiente producción y cumplir con las demandas del mercado'},
       { src: modalImgLarge4, caption: 'Nuestros productos son manufacturados con precisión y rapidez, garantizando calidad de excelencia y cumplimiento'},
       { src: modalImgLarge5, caption: 'En nuestros depósitos siempre tenemos existencia de nuestros productos'},
       { src: modalImgLarge6, caption: 'La logística es clave para un buen negocio, asi que nos preocupamos siempre optimizar el proceso al máximo'},
       { src: modalImgLarge7, caption: 'Los materiales para la producción son bien seleccionados y estudiados para un resultado final de calidad y además durable'}
    ]);
    const abrirModalEmpresa = (index) => {
      setSlideIndex(index);
    };
   const cerrarModal = () => {
      setSlideIndex(null); // Restablecer el slideIndex al cerrar el modal
    };
    const plusSlides = (n) => {
      if (slideIndex !== null) {
        setSlideIndex((prevIndex) => (prevIndex + n + totalSlides) % totalSlides);
      }
    };
    const totalSlides = 7;
return(

    <div className='contenedor--general--empresa'>
        <h2 className="titulo--empresa--principal">Conoce algo sobre quienes somos</h2>
        
        <div className='contenedor--texto--empresa'>

          <p>En MASTRO comenzamos como un modesto emprendimiento familiar, con el pasar de los años y la experiencia que esta da y con mucha dedicación y mejorando constantemente en la calidad de nuestros productos, logramos posicionarnos entre los principales fabricantes y distribuidores del país. </p>
          <p>Siempre fuimos obteniendo las últimas y más novedosas tecnologías que iban saliendo al mercado. De esta forma fuimos dándole a nuestros clientes mejor calidad en los productos que fabricamos y cumpliendo con los tiempos que ellos esperan.</p>
          <p>No solo ponemos el foco en tener tecnología de punta, sino que cuidamos de forma constante la calidad de las materias primas que usamos. Esto es clave para que nuestros artículos no solo tengan una magnífica y colorida presentación, sino también sea durable. Todos nuestros clientes siempre quedan satisfechos con la calidad de nuestros productos, ya que estos no presentan los problemas típicos de desgaste rápido que pueden presentar otras opciones en el mercado.</p>
          <p>En MASTRO también otro punto que tomamos muy seriamente en cuenta, es el respeto a los clientes y que su tiempo vale. Por ello siempre nos preocupamos por tener los pedidos completos en tiempo y forma sean chicos o grandes. Actualmente tenemos logística profesional capaz de poder cumplir con lo esperado y que tanto clientes locales o internacionales reciban a tiempo su encargo.</p>

        </div>

        {/* Modal */}

        {slideIndex !== null && (

        <div onKeyDown={manejoPulsadoEsc} tabIndex={0} ref={modalRef} className="contenedor--modal--empresa">
          
          <span className="cerrar--boton--modal" onClick={cerrarModal}>&times;</span>
          
          <div className='elementos--modal--empresa'>
            <div className='centro--modal--empresa'> 
            <a className="flecha--navegacion--menos" onClick={() => plusSlides(-1)}>&#10094;</a>
            <img src={imagenes[slideIndex].src} className = "imagen--ampliada--modal" alt={`Slide ${slideIndex + 1}`} />
            <a className="flecha--navegacion--mas" onClick={() => plusSlides(1)}>&#10095;</a>
            </div> 

            <div className="miniaturas--centrales">
              {imagenes.map((imagen, index) => (
              <img
                key={index}
                src={imagen.src}
                alt={`Miniatura ${index + 1}`}
                className={index === slideIndex ? "miniatura--seleccionada" : "miniatura"}
                onClick={() => abrirModalEmpresa(index)}
              />
              ))}
            </div>

            <div className='contenedor--leyendas--modal'>
              <p className='leyenda--modal'>{imagenes[slideIndex].caption}</p>
            </div>
          </div>
        </div>
      )}

      {/* Images used to open the lightbox */}
      <div className="empresa--miniaturas">
        <img src = {modalImg1} className="imagen--empresa--mini" onClick={() => abrirModalEmpresa(0)} />
        <img src = {modalImg2} className="imagen--empresa--mini" onClick={() => abrirModalEmpresa(1)} />
        <img src = {modalImg3} className="imagen--empresa--mini" onClick={() => abrirModalEmpresa(2)} />
        <img src = {modalImg4} className="imagen--empresa--mini" onClick={() => abrirModalEmpresa(3)}/>
        <img src = {modalImg5} className="imagen--empresa--mini" onClick={() => abrirModalEmpresa(4)}/>
        <img src = {modalImg6} className="imagen--empresa--mini" onClick={() => abrirModalEmpresa(5)}/>
        <img src = {modalImg7} className="imagen--empresa--mini" onClick={() => abrirModalEmpresa(6)}/>
        
      </div>
    </div>
);
}
export default Empresa